<template>
    <a-table :data-source="list" :pagination="pagination" :loading="loading" bordered rowKey="id">
        <a-table-column key="type_text"  title="费用类型" data-index="type_text" />
        <a-table-column key="serve_price" title="服务费" data-index="serve_price" />
        <a-table-column key="package" title="续费1年" data-index="package">
             <template #default="{ record }">
                 <span>{{record.package[0].sys_price.toFixed(2)}}</span>
             </template>
        </a-table-column>
        <a-table-column key="package" title="续费2年" data-index="package">
             <template #default="{ record }">
                 <span>{{record.package[1].sys_price.toFixed(2)}}</span>
             </template>
        </a-table-column>
        <a-table-column key="package" title="续费3年" data-index="package">
             <template #default="{ record }">
                 <span>{{record.package[2].sys_price.toFixed(2)}}</span>
             </template>
        </a-table-column>
        <a-table-column key="package" title="续费4年" data-index="package">
             <template #default="{ record }">
                 <span>{{record.package[3].sys_price.toFixed(2)}}</span>
             </template>
        </a-table-column>
        <a-table-column key="package" title="续费5年" data-index="package">
             <template #default="{ record }">
                 <span>{{record.package[4].sys_price.toFixed(2)}}</span>
             </template>
        </a-table-column>
        <a-table-column key="action" title="操作" data-index="action" width="140px">
             <template #default="{ record }">
                <a-button type="link" v-has:servePriceUpdate="() => edit(record)" >修改</a-button>
             </template>
        </a-table-column>
    </a-table>


 <a-modal v-model:visible="visible" title="服务费用" centered  @ok="submit" destroyOnClose :width="800">
      <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 12 }" ref="formRef" :model="form" :rules="rules" >
        <a-form-item label="费用类型：" required name="type_text">
          <a-input type="text" v-model:value="form.type_text" disabled ></a-input>
        </a-form-item>
        <a-form-item label="服务费：" name="serve_price" >
          <a-input  prefix="￥" suffix="RMB" v-model:value="form.serve_price" v-inputFilter:int />
        </a-form-item>
         <a-form-item label="续费价格:" required  :wrapper-col="{ span: 20 }" >
            <a-table  :data-source="form.package" bordered :columns="columns" :pagination="pagination" :loading="loading"  rowKey="id">
                 <template v-slot:operation="{ record }">
                     <div class="active" style="cursor: pointer;" @click="serviceEdit(record)">修改</div>
                 </template>
            </a-table>
        </a-form-item>
      </a-form>
    </a-modal>

    <a-modal v-model:visible="showModal" title="服务费用" centered  @ok="submit2" destroyOnClose :width="400">
     <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" ref="formRef2" :model="form2" :rules="rules2" >
        <a-form-item label="续费年限：" required >
          <a-input type="text" v-model:value="form2.valid_year" disabled ></a-input>
        </a-form-item>
        <a-form-item label="续费价格：" name="sys_price" >
          <a-input  prefix="￥" suffix="RMB" v-model:value="form2.sys_price" v-inputFilter:int />
        </a-form-item>
     </a-form>
    </a-modal>
</template>

<script>
import {ref,reactive, toRefs, onMounted} from 'vue'
import {brandAppsServeList,brandAppsServeInfo,brandAppsServeUpdate,brandAppsServeUpdatePrice} from "@/api/product";
import { $iscode } from "@/utils/app";
import { List, message } from "ant-design-vue";
const columns = [
    {
         title: "续费年限",
        dataIndex: "valid_year",
    },
     {
         title: "续费价格",
        dataIndex: "sys_price",
         customRender: ({ record }) => {
             return record.sys_price.toFixed(2)
             
         }
    },
    {
         title: "操作",
        key: "operation",
        align:"center",
        slots: {
          customRender: "operation",
        },
        width: 140,
    }
];

export default {
    setup(){
         const list = ref([]);

         const formRef = ref(null)
         const formRef2 = ref(null)
         
         const state = reactive({
             loading: false,
             pagination: false,
             visible:false,
             rules:{
                serve_price:[{ required: true, message: '请完善服务费！' }],
             },
             rules2:{
                 sys_price:[{ required: true, message: '请完善续费价格！' }]
             },
             form:{},
             form2:{},
             showModal:false
         })

         const methods = {
            async edit(record){
               let res = await brandAppsServeInfo(record.id).then(res=>res.data);
                if($iscode(res)){
                     state.form = res.data || {};
                      state.visible = true;
                 }else{
                      message.error(res.msg);
                 }
             },
             handleTableChange(){},
            async getList(){
                state.loading = true;
                 let res = await brandAppsServeList().then(res=>res.data);
                 state.loading = false;
                 if($iscode(res)){
                     list.value = res.data || [];
                 }else{
                      message.error(res.msg);
                 }
             },
             submit(){
                 formRef.value.validate().then(async (data) => {
                     const hide = message.loading('正在加载中...', 0);
                    let res = await brandAppsServeUpdate({...state.form}).then(res=>res.data);
                     setTimeout(hide);
                    if($iscode(res)){
                        message.success(res.msg)
                         state.visible = false;
                         methods.getList()
                     }else{
                        message.error(res.msg);
                     }
                 }).catch((err) => {
                   message.error(err.errorFields[0].errors);
                });
             },
             submit2(){
                 formRef2.value.validate().then(async (data) => {
                     const hide = message.loading('正在加载中...', 0);
                     let res = await brandAppsServeUpdatePrice({...state.form2}).then(res=>res.data);
                     setTimeout(hide);
                     if($iscode(res)){
                        message.success(res.msg)
                         console.log(state.form)
                         state.form.package.forEach(v => {
                             if(v.id==res.data.id){
                                v.sys_price=res.data.sys_price;
                             }
                         });
                        state.showModal = false; 
                     }else{
                        message.error(res.msg);
                     }
                   
                 }).catch((err) => {
                   message.error(err.errorFields[0].errors);
                });
             },
             //修改服务
             serviceEdit(record){
                //  console.log(record)
                 state.form2 = JSON.parse(JSON.stringify(record));
                 state.form2.sys_price = state.form2.sys_price.toFixed(2);
                 state.showModal = true;
             }
         }

         onMounted(()=>{
             methods.getList()
         })

        return {
            columns,
            ...toRefs(state),
            ...methods,
            list,
            formRef,
            formRef2
        }
    }
}
</script>
<style scoped>
.active:hover{
     color: #169bd5;
}
</style>
